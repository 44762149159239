module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Triplete.net","short_name":"Triplete","start_url":"/","background_color":"#ffffff","theme_color":"#1482d2","display":"standalone","icon":"content/icon.png","icons":[{"src":"content/images/icons/icon-48x48.png","type":"image/png","sizes":"48x48"},{"src":"content/images/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"content/images/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"content/images/icons/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"content/images/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"content/images/icons/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"content/images/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"content/images/icons/icon-256x256.png","type":"image/png","sizes":"256x256"},{"src":"content/images/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"content/images/icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"content/images/icons/icon-512x512-maskable.png","sizes":"512x512","type":"image/png","purpose":"any"}],"description":"Con Triplete il campionato di calcio non avrà più segreti per te: controlla la classifica e i risultati della Serie A quando vuoi, ovunque ti trovi. La tua squadra riuscirà a fare il triplete?","dir":"ltr","lang":"it-IT","scope":".","orientation":"portrait-primary","shortcuts":[{"name":"Marcatori","short_name":"Marcatori","description":"Classifica marcatori Serie A","url":"/marcatori","icons":[{"src":"content/images/icons/icon-96x96.png","sizes":"96x96"}]},{"name":"Partite","short_name":"Partite","description":"Calendario delle prossime partite di Serie A","url":"/partite","icons":[{"src":"content/images/icons/icon-96x96.png","sizes":"96x96"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6109f4b4b58fa1f652c47aba50358924"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"debug":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
